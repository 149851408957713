import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Form, Input, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../common/layout/header';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';
import { useMutation } from '@tanstack/react-query';
import { getCookie } from '../../utils/helper';
import {
  getCurrentUser,
  setAccessToken,
  setActiveTab,
} from '../../redux/slices/accountSlice';
import { setToastMessage } from '../../redux/slices/commonSlice';

const LoginPage = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  // const { setToastMessage, setAccessToken, setActiveTab } =
  //   useContext(GlobalContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...formValues }),
  };
  const mutation = useMutation(
    () => globalFetchAPI('user_login', requestOptions),
    {
      onSuccess(data) {
        if (data?.status_code === 200) {
          const access_token = getCookie('csrf_access_token');
          dispatch(setAccessToken(access_token));
          navigate('/summary');
          dispatch(setActiveTab('summary'));
        } else {
          dispatch(setToastMessage({ type: 'error', msg: t(data?.msg) }));
        }
      },
    }
  );

  useEffect(() => {
    if (currentUser?.email) {
      navigate('/summary');
      dispatch(setActiveTab('summary'));
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const loginHandler = async () => {
    if (Object.keys(formValues).length) {
      mutation.mutateAsync();
    }
  };

  return (
    <>
      <PageHeader />
      <div className='login-wrapper'>
        <Card className='login-form-container' title={t('signin')}>
          <Form onFinish={(e) => loginHandler()} autoComplete='off'>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: t('not_valid_email'),
                },
              ]}
            >
              <Input
                className='login-input'
                placeholder={t('email_address')}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </Form.Item>

            <Form.Item
              name='password'
              rules={[{ required: true, message: t('user_password_input') }]}
            >
              <Input.Password
                className='login-input'
                placeholder={t('user_password')}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
              />
            </Form.Item>

            <Form.Item>
              <Space className='login-footer-btn-wrapper'>
                <Button type='primary' htmlType='submit'>
                  {t('signin')}
                </Button>
                <div onClick={() => navigate('/register')}>
                  <Typography>{t('signup')}</Typography>
                </div>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default LoginPage;
